<template>
  <vz-card class="vz-calculator" :class="calculatorClass">
    <div
      v-if="isMain"
      class="vz-calculator-title"
      :class="{'text-medium': hideTrackOrder}"
    >
      {{ title }}
    </div>

    <vz-form class="vz-calculator-form">
      <vz-loader :status-loader="loader"/>
      <div class="vz-calculator-form-first">
        <div>
          <client-only>
            <city-autocomplete
                ref="dispatchLocation"
                v-model="form.from.guid"
                :search-options="dispatchSearchOptions"
                large
                label="Откуда"
                label-public-style
                class="mb-20"
                @change="calcPrice"
            />
            <vz-radiogroup
                v-model="form.from.type"
                :items="dispatchDirectionTypeItems"
                inline
                class="mb-30"
                @change="calcPrice"
            />
            <template #fallback>
              <div>
                <vz-skeleton height="19" width="47" class="my-5" />
                <vz-skeleton height="48" class="mb-20" />
              </div>
              <div class="flex" style="gap: 20px">
                <vz-skeleton height="24" width="87" />
                <vz-skeleton height="24" width="112" />
              </div>
            </template>
          </client-only>
        </div>
        <div v-if="isNetwork" class="vz-calculator-network-stub" />
        <vz-icon
          v-else
          url="/svg/icon/narrow-swap.svg"
          no-masked-icon
          class="svg-text-low-color m-10 pt-30 vz-cursor-pointer"
          size="24"
          @click="swap"
        />
        <div>
          <template v-if="isNetwork">
            <client-only>
              <NetworkAutocomplete
                ref="networkAutocomplete"
                v-model="networkGuid"
                label="Куда"
                large
                label-public-style
                placeholder="Торговая сеть"
                :rules="[formRules.required]"
                :filialOptionalInfo="'1'"
                @change="branchGuid = ''"
              />
              <template #fallback>
                <div>
                  <vz-skeleton height="19" width="47" class="my-5" />
                  <vz-skeleton height="48" class="mb-15" />
                </div>
              </template>
            </client-only>
            <client-only>
              <NetworkBranchAutocomplete
                ref="networkBranchAutocomplete"
                v-model="branchGuid"
                :network-guid="networkGuid"
                placeholder="Филиал"
                large
                label-public-style
                :disabled="!networkGuid"
                icon-name="home"
                :rules="[formRules.required]"
                :showTimetableTooltip="true"
                class="mt-15 mb-30"
                @change="calcPrice"
              />
              <template #fallback>
                <div>
                  <vz-skeleton height="48" class="mb-30" />
                </div>
              </template>
            </client-only>
          </template>
          <template v-else>
            <client-only>
              <city-autocomplete
                ref="destinationLocation"
                v-model="form.to.guid"
                :search-options="destinationSearchOptions"
                large
                label="Куда"
                label-public-style
                class="mb-20"
                :disabled="disabledDestination"
                @change="calcPrice"
              />
              <vz-radiogroup
                v-model="form.to.type"
                :items="destinationDirectionTypeItems"
                inline
                with-tooltips
                class="flex-wrap"
                :class="`direction-radiogroup-${destinationDirectionTypeItems.length}`"
                @change="calcPrice"
              />
              <template #fallback>
                <div>
                  <div>
                    <vz-skeleton height="19" width="47" class="my-5" />
                    <vz-skeleton height="48" class="mb-20" />
                  </div>
                  <div class="flex flex-wrap vz-radiogroup inline direction-radiogroup-3">
                    <vz-skeleton height="24" width="87" />
                    <vz-skeleton height="24" width="112" />
                    <vz-skeleton height="24" width="160" />
                  </div>
                </div>
              </template>
            </client-only>
          </template>
        </div>
      </div>

      <div class="vz-calculator-form-second">
        <client-only>
          <vz-input
            v-model="form.volume"
            maxValue="79"
            minValue="0"
            type="number"
            large
            label="Объем"
            label-public-style
            placeholder="0.01 м³"
            class="vz-focus-on-next"
            @change="calcPrice"
            @keydown.enter="focusOnNext"
          />
          <vz-input
            v-model="form.weight"
            maxValue="19999"
            minValue="0"
            type="number"
            large
            label="Вес"
            label-public-style
            placeholder="0.9 кг"
            class="vz-focus-on-next"
            @change="calcPrice"
            @keydown.enter="focusOnNext"
          />
          <VzCargoCategoryAutoComplete
            v-if="hasCategory"
            v-model="form.category"
            label-public-style
            no-text
            @update:modelValue="calcPrice"
          />
          <vz-input
            v-if="hasPromocode"
            large
            :disabled="true"
            label="Промокод"
            :max-length="15"
            label-public-style
            :placeholder="promocode"
            class="vz-calculator-form-promocode vz-focus-on-next"
            @keydown.enter="focusOnNext"
          />
          <vz-radiogroup
            v-if="hasOtherLocation"
            v-model="form.currency"
            :items="currencyList"
            class="vz-calculator-radiogroup"
            with-icon
            @change="onCurrencySelect"
          />

          <template #fallback>
            <div class="vz-input">
              <vz-skeleton height="19" width="47" class="my-5" />
              <vz-skeleton height="48" />
            </div>
            <div class="vz-input">
              <vz-skeleton height="19" width="47" class="my-5" />
              <vz-skeleton height="48" />
            </div>
            <div v-if="hasCategory">
              <vz-skeleton height="19" width="47" class="my-5" />
              <vz-skeleton height="48" />
            </div>
            <div v-if="promocode" class="vz-calculator-form-promocode vz-input">
              <vz-skeleton height="19" width="47" class="my-5" />
              <vz-skeleton height="48" />
            </div>
          </template>
        </client-only>
      </div>

      <div v-if="isNetwork && !branchGuid" class="vz-calculator-form-third network">
        <div>
          Бесплатный простой машины в рамках «окна доставки»
        </div>
      </div>
      <div v-else class="vz-calculator-price vz-calculator-form-third">
        <div v-if="manualCalculation" class="fs-14 color-low">
          <div>{{ manualCalculationText }}</div>
          <vz-button
            big
            variant="primary"
            class="mt-20 mx-auto"
            @click="openFeedbackModal"
          >
            Запрос на расчет
          </vz-button>
        </div>
        <div v-else-if="hasFakeLocation" class="fs-14 color-low">
          <span v-html="fakeLocationText" />
        </div>
        <client-only v-else>
          <div class="vz-calculator-price-container">
            <div>
              <span class="vz-calculator-price-title">Доставка</span>
              <nuxt-link
                class="vz-calculator-price-title color-primary"
                :to="scheduleLink"
                no-prefetch
              >{{ priceData?.delivery }}
              </nuxt-link>
            </div>

            <div class="vz-calculator-price-cost">
              <span>{{ priceData?.base }} {{ currencySymbol }}</span>
              <strong>
                {{ priceData?.total }} {{ currencySymbol }}
                <span>*</span>
              </strong>
            </div>
          </div>

          <template v-if="!isMain && !disabled">
            <p v-if="hideDescription" class="color-low fs-12 vz-calculator-note">
              *При заказе через личный кабинет, без учета параметров 1 места
            </p>
            <p v-if="!hideDescription" class="color-low fs-12 vz-calculator-note">
              *При заказе через личный кабинет и максимальных параметрах одного места: длина – 0.2 м,
              <span class="whitespace-nowrap">ширина – 0.2 м, высота – 0.2 м и вес – 2.5 кг</span>
            </p>
            <div
              v-if="timetableMessage"
              class="text-caption vz-border-card network-warehousing-card"
              :class="disabled ? 'color-inactive' : 'color-info2'"
            >
              <span v-html="timetableMessage" />
            </div>
          </template>
        </client-only>
      </div>
    </vz-form>

    <div class="vz-calculator-footer">
      <div class="vz-calculator-footer-block flex-align-items-center">
        <vz-button
          v-if="!disabled"
          large
          variant="primary"
          class="vz-calculator-button"
          :to="orderRoute"
          no-prefetch
          @click="onCalcClick"
        >
          {{ buttonText }}
        </vz-button>

        <template v-if="isMain && !disabled">
          <p v-if="hideDescription" class="color-low fs-12 vz-calculator-note">
            *При заказе через личный кабинет, без учета параметров 1 места
          </p>
          <p v-if="!hideDescription" class="color-low fs-12 vz-calculator-note">
            *При заказе через личный кабинет и максимальных параметрах одного места: длина – 0.2 м,
            <span class="whitespace-nowrap">ширина – 0.2 м, высота – 0.2 м и вес – 2.5 кг</span>
          </p>
        </template>
      </div>

      <template v-if="isMain && !hideTrackOrder">
        <div class="vz-calculator-title vz-calculator-top-line">
          Отследить / Оплатить груз
        </div>

        <div class="vz-calculator-footer-block flex-align-items-end">
          <vz-form v-model="isValid" ref="formRef">
            <client-only>
              <vz-input
                v-model="orderId"
                large
                label="Номер заказа"
                label-public-style
                :placeholder="orderPlaceholder"
                :rules="rules"
                max-length="9"
                @keyup.enter="toOrderManage"
              />
              <template #fallback>
                <div>
                  <vz-skeleton height="19" width="47" class="my-5" />
                  <vz-skeleton height="48" />
                </div>
              </template>
            </client-only>
          </vz-form>
          <vz-button
            large
            variant="primary"
            class="vz-calculator-button"
            icon-name="arrow-right"
            :icon-size="22"
            icon-right
            @click="toOrderManage"
          >
            Найти
          </vz-button>
        </div>
      </template>
    </div>
  </vz-card>
</template>

<script setup lang="ts">
import formRules from '@/common/vz-form-rules'
import CityAutocomplete from '~/components/autocomplete/city-autocomplete.vue';
import VzRadiogroup from '~/components/ui-kit/vz-radiogroup.vue'
import VzLoader from '~/components/ui-kit/vz-loader.vue'
import VzButton from '~/components/ui-kit/vz-button.vue'
import VzInput from '~/components/ui-kit/vz-input.vue'
import VzCard from '~/components/ui-kit/vz-card.vue'
import VzIcon from '~/components/ui-kit/vz-icon.vue'
import VzSkeleton from '~/components/ui-kit/vz-skeleton.vue'
import {PricePostData} from '~/types/api'
import {useCityStore} from '~/stores/city'
import {storeToRefs} from 'pinia'
import {getPrice} from '~/api/order'
import {Country, IPrice, Location, VzDialogPreset, VzRadioGroupItem} from '~/types/types'
import {manualCalculationText, fakeLocationText, getCountryFlagIcon, getCurrencyText} from '~/common/objects';
import {deepCopyObject, getNextFocusEl, orderNumberPlaceholder} from '~/common/functions';
import VzForm from "~/components/ui-kit/vz-form.vue";
import {ref, defineAsyncComponent} from "vue";
import {RouteLocationRaw} from 'vue-router';
import {getAddressByGuid} from '~/api/city';
import {asyncComputed} from '@vueuse/core';
import NetworkAutocomplete from '~/components/autocomplete/network-autocomplete.vue';
import NetworkBranchAutocomplete from '~/components/autocomplete/network-branch-autocomplete.vue';

const VzCargoCategoryAutoComplete = defineAsyncComponent(() => import('~/components/autocomplete/vz-cargo-category-auto-complete.vue'))

const props = withDefaults(defineProps<{
  isMain?: boolean
  hideTrackOrder?: boolean
  isNetwork?: boolean
  hasCategory?: boolean
  promocode?: string
  nameNetwork?: 'ozon' | 'yandex' | 'wildberries',
  hasPromocodeClass?: boolean
  disabledDestination?: boolean
}>(), {
  isMain: false,
  hideTrackOrder: false,
  isNetwork: false,
  hasCategory: false,
  promocode: '',
  nameNetwork: undefined,
  hasPromocodeClass: true,
  disabledDestination: false
})

const nameNetworkGuid = {
  'ozon': '8df38ccc-edf9-11eb-af00-506b4b6ad201',
  'wildberries': 'b30c00cf-edf6-11eb-af00-506b4b6ad201',
  'yandex': ''
}
const networkGuid = ref(nameNetworkGuid[props.nameNetwork || 'yandex'])
const branchGuid = ref()
const networkAutocomplete = ref()
const networkBranchAutocomplete = ref()

const isValid = ref(false);
const formRef = ref();
const rules = [
  formRules.required,
  formRules.decimal,
  {...formRules.equalLength, param: 9}
]

let controller = new AbortController()
const router = useRouter()

const cityStore = useCityStore()
const {setFrom, setTo} = cityStore
const {from, to, currentCity} = storeToRefs(cityStore)
const {$showToast, $showDialog} = useNuxtApp()
const dispatchLocation = ref()
const destinationLocation = ref()
const autoSelectCurrency = ref(true)
const orderId = ref(   router.currentRoute.value.query?.id || '')
const orderPlaceholder = 'Пример ' + orderNumberPlaceholder;

const form = ref<PricePostData>({
  volume: '',
  weight: '',
  from: {
    country: from.value.country || 'RU',
    guid: from.value.guid || 'e90f1820-0128-11e5-80c7-00155d903d03',
    type: 'terminal'
  },
  to: {
    country: to.value.country || 'RU',
    guid: to.value.guid || 'e90f19de-0128-11e5-80c7-00155d903d03',
    type: props.nameNetwork === 'yandex' ? 'pup' : 'terminal'
  },
  currency: 'RU',
  category: null
})

const loader = ref(false)
const priceData = ref<IPrice>({
  total: 0,
  base: 0,
  delivery: '',
  cost: {
    currency: {
      RU: true,
    }
  }
})

const title = computed(() => {
  return props.isMain && props.hideTrackOrder ? ' Рассчитать' : 'Рассчитать и заказать грузоперевозку'
})

const hasPromocode = computed(() => {
  return !!props.promocode?.length
})

const calculatorClass = computed(() => {
  return {
    'vz-calculator-main': props.isMain,
    'vz-calculator-has-promocode': hasPromocode.value && props.hasPromocodeClass,
    'vz-calculator-has-category': props.hasCategory,
  }
})

const pricePayload = computed(() => {
  const payload = deepCopyObject(form.value)
  if (dontHasTerminals && payload.to.type === 'terminal') {
    payload.to.type = 'address'
  }

  if (props.isNetwork) {
    payload.to.guid = undefined //networkBranchAutocomplete.value?.selected?.locationGuid;
    payload.to.network = branchGuid.value;
    payload.to.type = 'address'
    // delete payload.to.type
  }

  return {
    ...payload,
    volume: form.value.volume || '0.01',
    weight: form.value.weight || '0.9',
    // Дефолт значения в placeholder-е
  }
})

const dispatchDirectionTypeItems = computed(() => {
  return [
    {
      title: 'От адреса',
      value: 'address',
      disabled: Number(dispatchLocation.value?.selected?.terminal_only) === 1 || Number(dispatchLocation.value?.selected?.fake) === 1,
      disabledText: 'Доставка до адреса не производится'
    },
    {
      title: 'От терминала',
      value: 'terminal',
      disabled: Number(dispatchLocation.value?.selected?.has_terminals) === 0,
      disabledText: popoverFromDescription.value
    },
  ]
})

const destinationDirectionTypeItems = computed(() => {
  const result: VzRadioGroupItem[] = [
    {
      title: 'До адреса',
      value: 'address',
      disabled: Number(destinationLocation.value?.selected?.terminal_only) === 1 || Number(destinationLocation.value?.selected?.fake) === 1,
      disabledText: 'Доставка до адреса не производится'
    },
    {
      title: 'До терминала',
      value: 'terminal',
      disabled: dontHasTerminals.value,
      disabledText: popoverToDescription.value
    },
  ]

  if (!destinationLocation.value || +destinationLocation.value?.selected?.pup_count) {
    result.push({
      title: 'До пункта Яндекс',
      value: 'pup',
      disabled: false,
      tooltip: 'Макс. вес 30 кг при габаритах: 1,0м * 1,0м * 1,0м'
    })
  }

  return result
})

const dontHasTerminals = computed(() => {
  return Number(destinationLocation.value?.selected?.has_terminals) === 0
})

const timetableMessage = computed(() => {
  return networkBranchAutocomplete.value?.selected?.timetableMessage
})

const rclLocationFrom = asyncComputed(async () => {
  if (!dispatchLocation.value?.selected?.rcl_guid) {
    return {} as Location
  }
  return (await getAddressByGuid(dispatchLocation.value?.selected?.rcl_guid)).location
})

const rclLocationTo = asyncComputed(async () => {
  if (!destinationLocation.value?.selected?.rcl_guid) {
    return {} as Location
  }
  return (await getAddressByGuid(destinationLocation.value?.selected?.rcl_guid)).location
})

const popoverFromDescription = computed(() => {
  return getPopoverText(true)
})

const popoverToDescription = computed(() => {
  return getPopoverText(false)
})

const hasFakeLocation = computed(() => {
  return Number(dispatchLocation.value?.selected?.fake) === 1 || Number(destinationLocation.value?.selected?.fake) === 1
})

const manualCalculation = computed(() => {
  const formCountries = [dispatchLocation.value?.selected?.country, destinationLocation.value?.selected?.country]
  return formCountries.includes('AM') || formCountries.includes('GE')
})

const disabled = computed(() => {
  return hasFakeLocation.value || manualCalculation.value
})

const hasOtherLocation = computed(() => {
  return currencyList.value.length > 1
})

const buttonText = computed(() => {
  return props.isMain ? 'Рассчитать / Заказать' : 'Оформить заказ'
})

const currencySymbol = computed(() => {
  const obj: Record<string, boolean | any> = priceData.value?.cost?.currency || {}
  return getCurrencyText(
    Object.keys(obj).find(key => obj[key] === true),
    true
  )
})

const hideDescription = computed(() => {
  return currentCity.value.abbr === 'msk' || currentCity.value.abbr === 'spb'
})

const scheduleLink = computed(() => {
  return {
    name: 'run-schedule',
    query: {
      dispatch: form.value.from.guid,
      destination: form.value.to.guid,
      date: (new Date()).toISOString().split('T')[0],
      mode: 'schedule'
    }
  }
})

const orderRoute = computed((): RouteLocationRaw => {
  const {volume, weight, from, to, currency, category} = form.value
  const query: any = {
    location_dispatch: from.guid,
    dispatch_point: from.type,
    location_destination: to.guid,
    destination_point: form.value.to.type === 'pup' ? 'pickupPoint' : to.type,
    volume,
    weight,
    currency,
    category
  }

  if (disabled.value) {
    return ''
  }

  if (props.isNetwork) {
    query.is_networking = props.isNetwork;
    query.network_address_guid = networkBranchAutocomplete.value?.selected?.guid || '';
    query.location_destination = networkBranchAutocomplete.value?.selected?.locationGuid || '';
    query.tax_id = networkAutocomplete.value?.selected?.defaultTaxId;
  }

  if (hasPromocode.value) {
    query.promo = props.promocode
  }

  return {
    name: 'order-create',
    query
  }
})

const currencyList = computed(() => {
  return Object.keys(priceData.value?.cost.currency.list || {}).map(country => {
    return {
      title: getCurrencyText(country, true),
      value: country,
      url: getCountryFlagIcon(country)
    }
  })
})

const preset = computed(() => {
  const formCountries = [dispatchLocation.value?.selected?.country, destinationLocation.value?.selected?.country]
  const result: VzDialogPreset = {
    typeId: '',
    isShort: true,
    additionalFormData: {
      dispatchLocation: form.value.from.guid,
      destinationLocation: form.value.to.guid,
    }
  }
  if (formCountries.includes('GE')) {
    result.typeId = '4f582378-fa25-11ee-b1a1-0cc47a3455a3'
    result.message = 'Грузоперевозки Грузия'
  }
  if (formCountries.includes('AM')) {
    result.typeId = '4f582377-fa25-11ee-b1a1-0cc47a3455a3'
    result.message = 'Грузоперевозки Армения'
  }
  return result
})

const dispatchSearchOptions = computed(() => {
  return { destination: form.value.to.guid }
})

const destinationSearchOptions = computed(() => {
  return { dispatch: form.value.from.guid }
})

async function calcPrice() {
  await nextTick()
  if ((props.isNetwork && !branchGuid.value) || manualCalculation.value) {
    return;
  }

  loader.value = true
  controller.abort()
  controller = new AbortController()

  await getPrice(pricePayload.value, controller.signal)
    .then(data => {
      priceData.value = data
      loader.value = false
    })
    .catch(e => {
      if (e.name == 'AbortError') {
        console.log('Прервано!');
        return
      }
      loader.value = false
      const data = e.response?._data
      console.log('error', data)
      if (!hasFakeLocation.value) {
        $showToast({
          title: 'Ошибка',
          text: 'Для расчета стоимости необходимо перейти в основной калькулятор',
          variant: 'warning',
          autocloseDelay: 10000,
          buttonText: 'Перейти',
          buttonCB: () => {
            router.push(orderRoute.value)
          },
        })
      }
    })
}

function swap() {
  const tmp = deepCopyObject(form.value.from)
  form.value.from = deepCopyObject(form.value.to)
  if (form.value.from.type === 'pup') {
    form.value.from.type = 'address'
  }
  form.value.to = tmp
  form.value = deepCopyObject(form.value)
  calcPrice()
}

function onCurrencySelect() {
  autoSelectCurrency.value = false
  calcPrice()
}

function autoChangeCurrency(value: Country | null, oldValue?: Country | null) {
  if (!value || !oldValue || value === oldValue) {
    return
  }
  // Если следующая валюта рубли, либо стоит автоматический выбор валюты, либо новая валюта
  if (value === 'RU' || autoSelectCurrency.value || !currencyList.value.find(c => c.value === value)) {
    form.value.currency = value
    calcPrice()
  }
}

function autoChangeType() {
  if (!destinationLocation.value?.selected?.pup_count && form.value.to.type === 'pup') {
    form.value.to.type = 'address'
  }
}

async function toOrderManage() {
  if (!await formRef?.value?.validate()) {
    return;
  }

  loader.value = true
  await router.replace({ name: 'index', query: {id: orderId.value} })
  await router.push({name: 'order-manage', query: {id: orderId.value}})
  loader.value = false
}

function getPopoverText(isFrom: boolean) {
  const location = isFrom ? rclLocationFrom.value : rclLocationTo.value
  if (!location?.name) {
    return `Не указан населенный пункт. Доставка осуществляется ${isFrom ? 'от' : 'до'} адреса.`
  }
  return `Доставка осуществляется
    ${isFrom ? 'от' : 'до'} адреса.
     Ближайший терминал в г. ${location.name}`
}

function openFeedbackModal(): void {
  $showDialog({ name: 'feedback', preset: preset.value })
}

function onCalcClick() {
  if (props.isMain && VK) {
    VK?.Retargeting.Event('click-order');
  }
}

function focusOnNext() {
  const allFocusable = document.querySelectorAll('.vz-calculator-form .vz-focus-on-next input');
  getNextFocusEl(allFocusable, document.activeElement)?.focus()
}

onMounted(() => {
  calcPrice()
})

watch(() => from.value?.guid, (value) => {
  if (value) {
    form.value.from.guid = value
    calcPrice()
  }
})

watch(
  () => dispatchLocation.value?.selected,
  (value?: Location, oldValue?: Location) => {
    if (value?.guid) {
      autoChangeCurrency(value.country, oldValue?.country)
      form.value.from.country = value.country
      setFrom(value)
    }
  }
)

watch(
  () => destinationLocation.value?.selected,
  (value?: Location, oldValue?: Location) => {
    if (value?.guid) {
      autoChangeType()
      autoChangeCurrency(value.country, oldValue?.country)
      form.value.to.country = value.country
      setTo(value)
    }
  }
)

watch(currencyList, () => {
  if (!currencyList.value.length) {
    return
  }
  if (!currencyList.value.find(c => c.value === form.value.currency)) {
    form.value.currency = currencyList.value[0].value
    calcPrice()
  }
})

defineExpose({
  priceData,
  currencySymbol
})
</script>
